import React, { useEffect } from 'react'
import Spacer from '@components/Spacer'
import {
  SupportMain,
  SupportInnerMain,
  ButtonContainer,
  ButtonWrap,
  HundredImage,
  DesktopImage,
  MobileImage,
} from './index.style'
import { colors } from '@styles/vars/colors.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { Heading3, TextBody } from '@components/TextStyles'
import Button from '@components/Button'
import Container from '@components/Container'
import OneHundred from './images/101.svg'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'
import { useStore } from '@Store/'
import { setHeaderLight } from '@Store/'
import { setHeaderDark } from '@Store/'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import { animation } from '@styles/vars/animation.style'
import AnimateImage from '@components/animation/AnimateImage'

const Support = ({
  title,
  text,
  cta,
  link,
  desktopImg,
  mobileImg,
  donateButtons,
}) => {
  const [inViewRef, inView] = useInView({
    rootMargin: '-15% 0px -85% 0px',
  })
  const [, dispatch] = useStore()

  useEffect(() => {
    if (inView) {
      setHeaderLight(dispatch)
    } else {
      setHeaderDark(dispatch)
    }
  }, [inView, dispatch])

  return (
    <Container>
      <SupportMain ref={inViewRef}>
        <MobileImage>
          <AnimateImage>
            <GatsbyImage
              image={mobileImg.gatsbyImageData}
              alt={mobileImg.description}
            />
          </AnimateImage>
        </MobileImage>
        <Grid>
          <GridItem desk={1} deskStart={1}>
            <HundredImage>
              <AnimateSlideIn direction={`left`}>
                <img src={OneHundred} alt="" />
              </AnimateSlideIn>
            </HundredImage>
          </GridItem>

          <GridItem
            tabletP={10}
            tabletPStart={2}
            desk={5}
            deskStart={3}
            tabletL={8}
            tabletLStart={3}
            deskAlign={`center`}
          >
            <SupportInnerMain>
              <Heading3 color={colors.pink2}>
                <AnimateSplitText>{title}</AnimateSplitText>
              </Heading3>
              <Spacer size={[21, 35]} />
              <TextBody color={colors.light}>
                <AnimateSplitText>{text.supportText}</AnimateSplitText>
              </TextBody>
              <Spacer size={[40, 42]} />
              <AnimateSlideIn>
                <Button
                  variant="secondary"
                  type="externalLink"
                  href={link.url}
                  target="_blank"
                >
                  {link.text}
                </Button>
              </AnimateSlideIn>
              <Spacer size={[55, 74]} />
              <TextBody color={colors.light}>
                <AnimateSplitText>{cta}</AnimateSplitText>
              </TextBody>
              <Spacer size={[18, 24]} />
              <ButtonContainer>
                {React.Children.toArray(
                  donateButtons.map((button, buttonIndex) => {
                    return (
                      <ButtonWrap>
                        <AnimateSlideIn
                          delay={animation.lineDuration * buttonIndex}
                        >
                          <Button
                            variant="primary"
                            type="externalLink"
                            theme="outline"
                            href={button.url}
                            target="_blank"
                          >
                            {button.text}
                          </Button>
                        </AnimateSlideIn>
                      </ButtonWrap>
                    )
                  })
                )}
              </ButtonContainer>
            </SupportInnerMain>
          </GridItem>
          <GridItem desk={3} deskStart={9} deskAlign={`center`}>
            <DesktopImage>
              <AnimateImage>
                <GatsbyImage
                  image={desktopImg.gatsbyImageData}
                  alt={desktopImg.description}
                />
              </AnimateImage>
            </DesktopImage>
          </GridItem>
        </Grid>
      </SupportMain>

      <Spacer size={[48, 110]} />
    </Container>
  )
}

export default Support
