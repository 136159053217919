import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Video from './videos/book.mp4'
import { BookVideoMain } from './index.style'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const BookVideo = ({ video }) => {
  const wrapRef = useRef(null),
    videoRef = useRef(null)

  useEffect(() => {
    if (!wrapRef.current) return

    let seeking = false

    const update = progress => {
      if (seeking) return
      seeking = true
      const time = gsap.utils.mapRange(0, 1, 0, 3, progress)
      videoRef.current.currentTime = time
    }

    const videoTrigger = ScrollTrigger.create({
      trigger: wrapRef.current,
      start: 'top bottom',
      end: 'center center',
      onUpdate: self => update(self.progress),
    })

    videoRef.current.addEventListener('seeked', () => {
      seeking = false
    })

    videoRef.current.pause()

    return () => {
      if (videoTrigger) videoTrigger.kill()
    }
  }, [])

  return (
    <BookVideoMain ref={wrapRef}>
      <video
        ref={videoRef}
        src={Video}
        autoPlay={true}
        preload="auto"
        muted
        playsInline
      />
    </BookVideoMain>
  )
}

BookVideo.propTypes = {
  video: PropTypes.object,
}

export default BookVideo
