import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'
import { paddingMax } from '@components/Container/index.style'

export const FiltersMain = styled.div``

export const FiltersText = styled.div`
  padding-top: 5.6rem;

  ${mq.tabletL} {
    padding-top: 0;
  }
`

export const FiltersImage = styled.div`
  display: ${props => (props.desktopOnly ? `none` : `block`)};

  ${mq.desk} {
    display: block;

    ${props =>
      props.desktopOnly &&
      `
      transform: translateX(${paddingMax}px) translateX(10px);
    `}
  }
`

export const FiltersLinks = styled.ul`
  display: flex;
  list-style-type: none;

  li:nth-child(1n + 2) {
    margin-left: 2.4rem;

    ${mq.desk} {
      margin-left: 4rem;
    }
  }
`
