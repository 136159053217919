import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import { Heading3, TextBody, Caption } from '@components/TextStyles'
import { ImageTextCenterStyle, IntroText } from './index.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { colors } from '@styles/vars/colors.style'
import Button from '@components/Button'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import Vinyls from '@components/Home/Vinyls'

const ImageTextCenter = ({ subheading, title, text, cta, link }) => {
  return (
    <ImageTextCenterStyle>
      <Container>
        <Grid>
          <GridItem
            tabletP={10}
            tabletPStart={2}
            tabletL={6}
            tabletLStart={4}
            deskM={4}
            deskMStart={5}
          >
            <IntroText>
              <Caption color={colors.pink2}>
                <AnimateSplitText>{subheading}</AnimateSplitText>
              </Caption>
              <Spacer size={[16, 27]} />
              <Heading3 color={colors.pink2}>
                <AnimateSplitText>{title}</AnimateSplitText>
              </Heading3>
              <Spacer size={57} />
              <TextBody>
                <AnimateSplitText>{text}</AnimateSplitText>
              </TextBody>
              <Spacer size={[50, 60]} />
              <AnimateSlideIn>
                <Button
                  variant="secondary"
                  type="externalLink"
                  href={link}
                  target="_blank"
                >
                  {cta}
                </Button>
              </AnimateSlideIn>
            </IntroText>
          </GridItem>
          <GridItem
            tabletP={8}
            tabletPStart={2}
            tabletLOrder={-1}
            tabletL={12}
            tabletLStart={0}
          >
            <Vinyls />
          </GridItem>
        </Grid>
        <Spacer size={[130, 265]} />
      </Container>
    </ImageTextCenterStyle>
  )
}

ImageTextCenter.propTypes = {
  subheading: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  cta: PropTypes.string,
  link: PropTypes.string,
}

export default ImageTextCenter
