import styled from 'styled-components'
import { colors } from '@styles/vars/colors.style'
import { clamp } from '@styles/utils/conversion.style'
import { easings } from '@styles/vars/easings.style'
import { mq } from '@styles/vars/media-queries.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { Heading5, TextBody } from '@styles/vars/textStyles.style'
import { ButtonMain } from '@components/Button/index.style'
import { transparentize } from 'polished'
import { ContainerMain } from '@components/Container/index.style'

export const ImageCarouselMain = styled.div`
  overflow: hidden;
  position: relative;

  &:before {
    background-color: ${transparentize(0.88, colors.darkblue)};
    content: '';
    height: 0.1rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  ${mq.tabletL} {
    ${TextBody} {
      ${clamp(
        'max-width',
        480,
        590,
        breakpoints.tabletL,
        breakpoints.contained
      )};
    }
  }
`

export const Carousel = styled.ul`
  display: flex;
  list-style: none;
  overflow: scroll;
  ${clamp('padding-left', 24, 48)};
  ${clamp('padding-right', 24, 48)};
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;

  ${mq.desk} {
    border-top: none;
    overflow: hidden;
    padding: 0;
  }

  ::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`

export const CarouselItem = styled.li`
  padding-right: 2.4rem;

  &:last-child {
    padding-right: 0;
  }

  a {
    color: ${colors.offWhite};
    text-decoration: none;
  }

  svg {
    display: inline-block;
    ${clamp('margin-left', 4, 6)};
    position: relative;
  }
`

export const ImageCarouselItems = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Heading5} {
    padding-top: 4.3rem;

    ${mq.desk} {
      padding-top: 0;
    }
  }
`

export const ImageCarouselButtons = styled.div`
  display: none;
  text-align: right;

  ${mq.desk} {
    display: block;
  }

  ${ContainerMain} {
    display: flex;
    justify-content: flex-end;
  }

  ${ButtonMain}:last-child {
    margin-left: 1.2rem;
  }
`

export const ImageCarouselItemList = styled.ul`
  list-style-type: none;
  position: relative;
`

export const ImageCarouselItem = styled.li`
  padding-bottom: 2.4rem;
  padding-right: 1.3rem;
  transform: translateX(${props => props.x}%);
  transition: transform 0.8s ${easings.out.cubic};

  &:nth-child(1n + 2) {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .gatsby-image-wrapper {
    border-radius: 1rem;
    overflow: hidden;
  }

  ${mq.desk} {
    padding-right: 2.4rem;
  }
`

export const ImageCarouselItemImage = styled.div`
  padding-bottom: 100%;
  position: relative;

  ${mq.desk} {
    ${clamp('height', 411, 470, breakpoints.desk, breakpoints.contained)};
    padding-bottom: 0;
    transform: scaleY(${props => 1 - props.yScaleOffset});
    transform-origin: 50% 100%;
    transition: transform 0.7s ${easings.out.cubic};
    will-change: transform;
  }

  > div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    ${mq.desk} {
      ${clamp('height', 411, 470, breakpoints.desk, breakpoints.contained)};
      top: auto;
      transform: scaleY(${props => 1 + props.yScaleOffset});
      transform-origin: 50% 100%;
      transition: transform 0.7s ${easings.out.cubic};
    }
  }
`

export const ImageCarouselDots = styled.ul`
  display: flex;
  justify-content: center;
  list-style-type: none;

  ${mq.desk} {
    display: none;
  }
`

export const ImageCarouselDot = styled.li`
  border: solid 0.1rem ${colors.dark};
  border-radius: 50%;
  height: 0.6rem;
  opacity: ${props => (props.active ? 1 : 0.3)};
  margin: 0 0.4rem;
  position: relative;
  width: 0.6rem;

  &:before {
    background-color: ${colors.dark};
    border: solid 0.1rem ${colors.dark};
    border-radius: 50%;
    content: '';
    height: 100%;
    left: 0;
    opacity: ${props => (props.active ? 1 : 0)};
    position: absolute;
    top: 0;
    transform: translateY(${props => (props.active ? 0 : `25%`)});
    width: 100%;
  }

  ${mq.tabletL} {
    // Hide the last two to account for a larger number of items on screen at this size
    &:last-child,
    &:nth-last-child(2) {
      display: none;
    }
  }
`
