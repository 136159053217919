import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Spacer from '@components/Spacer'
import { CardsMain, CardsInner, CardsPair, CardsSingle } from './index.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import AnimateImage from '@components/animation/AnimateImage'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { animation } from '@styles/vars/animation.style'

const Cards = ({ cards }) => {
  const [selectedCards, setSelectedCards] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ])

  const spacers = [
    [0, 0],
    [57, 150],
    [114, 300],
    [172, 450],
    [142, 370],
    [222, 587],
    [159, 418],
  ]

  useEffect(() => {
    const newCards = cards
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
      .slice(0, 14)
      .reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, [])

    setSelectedCards(newCards)
  }, [cards])

  useEffect(() => {
    ScrollTrigger.refresh()
  }, selectedCards)

  return (
    <CardsMain>
      <CardsInner>
        <Grid mobile={7}>
          {React.Children.toArray(
            selectedCards.map((cardPair, cardPairIndex) => {
              return (
                <GridItem mobile={1}>
                  <CardsPair data-speed={animation.mixedSpeeds[cardPairIndex]}>
                    <Spacer size={spacers[cardPairIndex]} />
                    {React.Children.toArray(
                      cardPair.map((card, cardIndex) => {
                        return (
                          <CardsSingle>
                            <AnimateImage>
                              {card.gatsbyImageData ? (
                                <GatsbyImage
                                  image={card.gatsbyImageData}
                                  alt={card.description}
                                />
                              ) : (
                                `0`
                              )}
                            </AnimateImage>
                          </CardsSingle>
                        )
                      })
                    )}
                  </CardsPair>
                </GridItem>
              )
            })
          )}
        </Grid>
      </CardsInner>
    </CardsMain>
  )
}

Cards.propTypes = {
  cards: PropTypes.array,
}

export default Cards
