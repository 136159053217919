import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { Heading3 } from '@styles/vars/textStyles.style'

export const ImageTextCenterStyle = styled.div`
  overflow: hidden;
  position: relative;
  text-align: left;

  ${mq.tabletL} {
    text-align: center;
  }
`

export const IntroText = styled.div`
  margin-top: 0;
  position: relative;
  z-index: 2;

  ${mq.tabletL} {
    margin-top: -15rem;
  }

  ${mq.desk} {
    ${Heading3} {
      margin: 0 auto;
      ${clamp('max-width', 450, 530, breakpoints.desk, breakpoints.contained)};
    }
  }
`
