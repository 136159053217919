import styled from 'styled-components'

export const BookVideoMain = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  video {
    height: auto;
    width: 100%;
  }
`
