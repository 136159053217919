import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function Seo({ data, slug, meta = [] }) {
  const { seoTitle: title, seoDescription: description, seoImage: image } = data
  const {
    contentfulGlobals: {
      siteUrl,
      siteTitle,
      siteDescription: { siteDescription },
      siteShareImage,
      twitterUsername,
    },
  } = useStaticQuery(
    graphql`
      query {
        contentfulGlobals {
          siteUrl
          siteTitle
          siteDescription {
            siteDescription
          }
          siteShareImage {
            file {
              url
            }
          }
          twitterUsername
        }
      }
    `
  )

  const url = `${siteUrl.replace(/\/$/, '')}/${
    slug === '' ? '' : `${slug.replace(/^\/+|\/+$/g, '')}/`
  }`
  const ogTitle = title ? `${title} | ${siteTitle}` : siteTitle
  const ogDescription = description || siteDescription
  const ogImage = `https:${image ? image.file.url : siteShareImage.file.url}`
  const metaTags = [
    {
      name: `description`,
      content: ogDescription,
    },
    {
      property: `og:title`,
      content: ogTitle,
    },
    {
      property: `og:description`,
      content: ogDescription,
    },
    {
      property: `og:image`,
      content: ogImage,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:url`,
      content: url,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: ogTitle,
    },
    {
      name: `twitter:description`,
      content: ogDescription,
    },
  ]

  if (twitterUsername) {
    metaTags.push({
      name: `twitter:site`,
      content: `@${twitterUsername}`,
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={ogTitle}
      meta={[...metaTags, ...meta]}
    />
  )
}

Seo.propTypes = {
  data: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default Seo
