import { easings } from '@styles/vars/easings.style'
import styled from 'styled-components'
import { mq } from '../../../styles/vars/media-queries.style'

export const VinylsMain = styled.div`
  height: 17.3rem;
  margin-top: 5.6rem;
  perspective: 750px;
  position: relative;
  width: 32rem;

  ${mq.tabletL} {
    height: 47.6rem;
    margin: 0 auto;
    width: 92.8rem;
  }

  ${mq.deskM} {
    margin-top: 25rem;
    width: 120.8rem;
  }
`

export const VinylSingle = styled.div`
  bottom: -10rem;
  left: 50%;
  margin-left: -7.5rem;
  perspective: 500px;
  opacity: ${props => (props.visible ? 1 : 0)};
  position: absolute;
  transform: translateY(50%) rotateZ(-60deg)
    rotateZ(${props => props.offset * (props.visible ? 20 : 0)}deg)
    rotateZ(${props => (props.visible ? 0 : 80)}deg) translateY(-125%)
    scale(${props => (props.visible ? 1 : 1.5)});
  transition: ${props =>
    props.visible
      ? `transform 2s ${easings.out.cubic}, opacity 0.4s ${easings.out.cubic}`
      : ``};
  transition-delay: ${props => (props.visible ? props.offset * 0.2 : 0)}s;
  height: 15rem;
  width: 15rem;

  ${mq.tabletLMax} {
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(6),
    &:nth-of-type(7) {
      display: none;
    }
  }

  ${mq.tabletL} {
    bottom: -8rem;
    height: 12rem;
    margin-left: -6rem;
    transform: translateY(50%) rotateZ(-60deg)
      rotateZ(${props => props.offset * (props.visible ? 20 : 0)}deg)
      rotateZ(${props => (props.visible ? 0 : 160)}deg) translateY(-350%)
      scale(${props => (props.visible ? 1 : 1.5)});
    width: 12rem;
  }

  ${mq.deskM} {
    bottom: -16rem;
    height: 16rem;
    margin-left: -8rem;
    width: 16rem;
  }
`

export const VinylSingleDisc = styled.div`
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
`

export const VinylSingleInner = styled.div`
  position: relative;
  transform: rotateY(${props => (props.visible ? 0 : -10)}deg);
  transform-origin: 50% 50%;
  transition: ${props =>
    props.visible ? `transform 1.5s ${easings.out.cubic}` : ``};
  transition-delay: ${props => (props.visible ? props.offset * 0.2 + 0.2 : 0)}s;

  ${mq.tabletL} {
    transform: rotateY(${props => (props.visible ? 0 : -100)}deg);
  }

  ${VinylSingleDisc} {
    transform: translateX(${props => (props.visible ? 25 : 0)}%);
    transition: transform 1s ${easings.out.cubic};
    transition-delay: ${props => props.offset * 0.2 + 1.2}s;
  }
`
