import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import { Heading1, TextBody } from '@styles/vars/textStyles.style'
import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import BG from './images/mobile.png'

export const HeroMain = styled.div`
  background-image: url(${BG});
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;

  ${mq.desk} {
    background-image: ${props =>
      props.showBackground ? `url(${BG})` : 'none'};
  }
`

export const HeroInner = styled.div`
  color: ${colors.light};
  height: 94vh;
  position: relative;
  z-index: 2;

  ${mq.desk} {
    height: 100vh;
  }
`

export const HeroText = styled.div`
  left: 3rem;
  position: absolute;
  right: 3rem;
  text-align: center;
  top: 25%;
  z-index: 2;

  ${Heading1} {
    margin: 0 auto;
  }

  ${mq.tabletL} {
    top: 38%;

    ${Heading1} {
      max-width: none;
    }
  }

  ${TextBody} {
    margin: 0 auto;

    ${clamp('max-width', 500, 570, breakpoints.tabletP, breakpoints.contained)};
  }
`

export const HeroLogoWrap = styled.div`
  ${clamp('height', 66, 103, breakpoints.desk, breakpoints.contained)}
  margin: 0 auto;
  ${clamp('width', 1000, 1545, breakpoints.desk, breakpoints.contained)}
`

export const HeroButton = styled.div`
  bottom: 5rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
`

export const SceneDebug = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
`

export const SceneCanvas = styled.canvas`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -2;

  ${mq.desk} {
    display: block;
  }
`

export const HeroScroller = styled.div`
  display: none;
  height: 100vh;
  position: relative;

  ${mq.desk} {
    display: block;
  }
`
