import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'
import { colors } from '../../../styles/vars/colors.style'
import { clamp } from '../../../styles/utils/conversion.style'

export const SupportMain = styled.div`
  background-color: ${colors.blueGrey3};
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 5rem;

  ${mq.tabletL} {
    margin-bottom: 0;
  }
`
export const SupportInnerMain = styled.div`
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  ${clamp('padding-top', 43, 150)};
  ${clamp('padding-bottom', 43, 150)};

  a {
    color: ${colors.light};

    &:before {
      background-color: ${colors.light};
    }
  }

  ${mq.tabletP} {
    padding-right: 0;
    padding-left: 0;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${mq.tabletL} {
    flex-wrap: nowrap;
  }
`

export const ButtonWrap = styled.div`
  margin-right: 1.2rem;
  margin-bottom: 1.2rem;
  width: 45%;

  a {
    width: 100%;
  }
`

export const HundredImage = styled.div`
  display: none;
  height: 100%;
  align-items: center;
  padding: 5.2rem 0;

  ${mq.desk} {
    display: flex;
  }
`
export const DesktopImage = styled.div`
  display: none;

  ${mq.desk} {
    display: block;
  }
`
export const MobileImage = styled.div`
  display: block;
  height: 100%;

  ${mq.desk} {
    display: none;
  }
`
