import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { colors } from '../../../styles/vars/colors.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { Heading2 } from '@styles/vars/textStyles.style'

export const IntroHundred = styled.div`
  svg {
    margin: 0 auto;
  }
`

export const IntroMain = styled.div`
  color: ${colors.dark};
  position: relative;
  text-align: center;
`

export const IntroText = styled.div`
  ${Heading2} {
    margin: 0 auto;
  }

  ${mq.desk} {
    margin: 0 auto;
    max-width: ${clamp(
      'max-width',
      800,
      1000,
      breakpoints.desk,
      breakpoints.contained,
      false
    )};
  }
`
