import React from 'react'
import { useInView } from 'react-intersection-observer'
import { useStore } from '@Store/'
import {
  VinylsMain,
  VinylSingle,
  VinylSingleInner,
  VinylSingleDisc,
} from './index.style'
import Disc1 from './images/disc1.png'
import Disc2 from './images/disc2.png'
import Disc3 from './images/disc3.png'
import Disc4 from './images/disc4.png'
import Disc5 from './images/disc5.png'
import Disc6 from './images/disc6.png'
import Disc7 from './images/disc7.png'
import DiscImg from './images/disc.png'

const Vinyls = () => {
  const [ref, inView] = useInView({
    rootMargin: '0px',
    triggerOnce: false,
  })
  const [store] = useStore()
  const { showIntroMask, showPageMask } = store

  const records = [Disc1, Disc2, Disc3, Disc4, Disc5, Disc6, Disc7]

  return (
    <VinylsMain ref={ref}>
      {React.Children.toArray(
        records.map((record, recordIndex) => {
          return (
            <VinylSingle
              offset={recordIndex}
              visible={inView && !showIntroMask && !showPageMask}
            >
              <VinylSingleInner
                offset={recordIndex}
                visible={inView && !showIntroMask && !showPageMask}
              >
                <img src={record} alt="Vinyl" />
                {recordIndex === records.length - 1 ? (
                  <VinylSingleDisc>
                    <img src={DiscImg} alt="Vinyl" />
                  </VinylSingleDisc>
                ) : (
                  ``
                )}
              </VinylSingleInner>
            </VinylSingle>
          )
        })
      )}
    </VinylsMain>
  )
}

Vinyls.propTypes = {}

export default Vinyls
