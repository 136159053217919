import React from 'react'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import { Heading2, TextBody } from '@components/TextStyles'
import { IntroHundred, IntroMain, IntroText } from './index.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import Hundred from '@svgs/100'
import { colors } from '@styles/vars/colors.style'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import AnimateSplitText from '@components/animation/AnimateSplitText'

const Intro = ({ title, text }) => {
  return (
    <IntroMain>
      <Container>
        <Spacer size={[80, 115]} />
        <Grid>
          <GridItem
            tabletP={10}
            tabletPStart={2}
            tabletL={8}
            tabletLStart={3}
            desk={8}
            deskStart={3}
          >
            <IntroText>
              <AnimateSlideIn>
                <IntroHundred>
                  <Hundred />
                </IntroHundred>
              </AnimateSlideIn>
              <Spacer size={[60, 82]} />

              <Heading2 color={colors.pink2} maxWidth={6.6}>
                <AnimateSplitText type={`lines,chars`}>
                  {title}
                </AnimateSplitText>
              </Heading2>

              <Spacer size={[32, 43]} />
              <TextBody>
                <AnimateSplitText>{text.musicBuildsText}</AnimateSplitText>
              </TextBody>
            </IntroText>
          </GridItem>
        </Grid>
        <Spacer size={[10, 60]} />
      </Container>
    </IntroMain>
  )
}

Intro.propTypes = {}

export default Intro
