import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { useStore, setHeaderLight } from '@Store/'
import Seo from '@components/Seo'
import Hero from '@components/Home/Hero'
import Intro from '@components/Home/Intro'
import ImageText from '@components/Home/ImageText'
import ImageTextCenter from '@components/Home/ImageTextCenter'
import Filters from '@components/Home/Filters'
import Cards from '@components/Home/Cards'
import Support from '@components/Home/Support'
import ImageCarousel from '@components/ImageCarousel'
import Footer from '@components/Footer'
import gsap from 'gsap'
import { animation } from '@styles/vars/animation.style'

const IndexPage = ({ data }) => {
  const {
    seo,
    heroDescription,
    musicBuildsTitle,
    musicBuildsText,
    block1Cards,
    coffeetableSubheading,
    coffeetableTitle,
    coffeetableText,
    coffeetableLink,
    ctaText,
    coffeebookImage,
    hundrerTitle,
    hundredSubheading,
    hundredLink,
    hundredText: { hundredText },
    hundredCtaText,
    hundredDesktopImage,
    hundredImageMobile,
    block4Title,
    block4Links,
    block4Images,
    instagramTitle,
    instagramText,
    instagramImages,
    supportTitle,
    supportText,
    supportCta,
    supportLink,
    supportButtons,
    desktopImage,
    mobileImage,
  } = data.contentfulHome
  const [, dispatch] = useStore()

  useEffect(() => {
    gsap.delayedCall(animation.maskShowDuration, () => setHeaderLight(dispatch))
  }, [dispatch])

  return (
    <>
      <Seo data={seo} slug="" />
      <Hero debug={false} description={heroDescription} />
      <Intro title={musicBuildsTitle} text={musicBuildsText} />
      <Cards cards={block1Cards} />
      <ImageText
        subheading={coffeetableSubheading}
        title={coffeetableTitle}
        text={coffeetableText}
        cta={ctaText}
        link={coffeetableLink}
        image={coffeebookImage}
      />
      <ImageTextCenter
        subheading={hundredSubheading}
        title={hundrerTitle}
        text={hundredText}
        cta={hundredCtaText}
        link={hundredLink}
        image={hundredImageMobile}
        imageDesktop={hundredDesktopImage}
      />
      <Filters title={block4Title} links={block4Links} images={block4Images} />
      <ImageCarousel
        title={instagramTitle}
        text={instagramText}
        images={instagramImages}
      />
      <Support
        title={supportTitle}
        text={supportText}
        cta={supportCta}
        desktopImg={desktopImage}
        mobileImg={mobileImage}
        link={supportLink}
        donateButtons={supportButtons}
      />
      <Footer />
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomePage {
    contentfulHome {
      seo {
        ...SEO
      }
      heroDescription {
        heroDescription
      }
      musicBuildsTitle
      musicBuildsText {
        musicBuildsText
      }
      block1Cards {
        gatsbyImageData(
          quality: 90
          resizingBehavior: FILL
          layout: FULL_WIDTH
          height: 812
          width: 568
        )
        description
      }
      coffeetableTitle
      coffeetableSubheading
      ctaText
      coffeetableLink
      coffeetableText {
        coffeetableText
      }
      coffeebookImage {
        gatsbyImageData(quality: 90)
        description
      }
      hundrerTitle
      hundredSubheading
      hundredLink
      hundredText {
        hundredText
      }
      hundredCtaText
      hundredDesktopImage {
        gatsbyImageData(quality: 90)
        description
      }
      hundredImageMobile {
        gatsbyImageData(quality: 90)
        description
      }
      block4Title
      block4Links {
        text
        url
      }
      block4Images {
        gatsbyImageData(quality: 90)
        description
      }
      instagramTitle
      instagramText {
        instagramText
      }
      instagramImages {
        gatsbyImageData(quality: 90)
        title
        description
      }
      supportTitle
      supportText {
        supportText
      }
      supportCta
      mobileImage {
        gatsbyImageData(layout: FULL_WIDTH)
        description
      }
      desktopImage {
        gatsbyImageData(quality: 90)
        description
      }
      supportLink {
        text
        url
      }
      supportButtons {
        url
        text
      }
    }
  }
`
