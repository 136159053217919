import React from 'react'

const Hundred = ({
  width = 74,
  height = 32,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 74 32"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M25.3368 4.44446C23.1118 4.44446 20.9366 5.12218 19.0866 6.39192C17.2365 7.66166 15.7946 9.46639 14.9431 11.5779C14.0916 13.6894 13.8688 16.0128 14.3029 18.2544C14.7369 20.4959 15.8084 22.5549 17.3818 24.171C18.9552 25.7871 20.9597 26.8876 23.1421 27.3335C25.3244 27.7794 27.5864 27.5505 29.6421 26.6759C31.6978 25.8013 33.4548 24.3202 34.691 22.4199C35.9272 20.5196 36.587 18.2855 36.587 16C36.587 12.9353 35.4017 9.99608 33.2919 7.829C31.1821 5.66191 28.3206 4.44446 25.3368 4.44446ZM25.3368 24.7273C23.6564 24.7273 22.0136 24.2154 20.6164 23.2565C19.2191 22.2975 18.1301 20.9345 17.487 19.3398C16.8439 17.7451 16.6756 15.9903 17.0034 14.2974C17.3313 12.6045 18.1405 11.0494 19.3288 9.8289C20.5171 8.60837 22.0311 7.77717 23.6792 7.44043C25.3274 7.10369 27.0358 7.27652 28.5883 7.93706C30.1409 8.59761 31.4679 9.7162 32.4015 11.1514C33.3351 12.5866 33.8334 14.2739 33.8334 16C33.8293 18.3133 32.9327 20.5306 31.3402 22.1664C29.7477 23.8021 27.589 24.723 25.3368 24.7273Z"
        fill="url(#paint0_linear_755_2082)"
      />
      <path
        d="M0.5 31.6929H7.65914L7.65914 1.38992L0.5 1.38992L0.5 31.6929ZM3.25353 4.2182L4.90564 4.2182L4.90564 28.8646H3.25353L3.25353 4.2182Z"
        fill="url(#paint1_linear_755_2082)"
      />
      <path
        d="M57.8993 1.83671e-05C54.8194 0.0048115 51.8101 0.947266 49.2516 2.70828C46.693 4.46929 44.7001 6.96982 43.5247 9.89385C42.3494 12.8179 42.0443 16.0342 42.648 19.1363C43.2517 22.2384 44.7371 25.087 46.9165 27.3222C49.096 29.5575 51.8716 31.0789 54.8926 31.6943C57.9136 32.3097 61.0445 31.9914 63.8895 30.7797C66.7344 29.568 69.1658 27.5173 70.8764 24.8866C72.587 22.2559 73.5 19.1635 73.5 16C73.5 13.8968 73.0963 11.8143 72.312 9.87147C71.5277 7.92868 70.3782 6.16378 68.9293 4.67773C67.4803 3.19167 65.7603 2.01363 63.8677 1.21098C61.975 0.408332 59.9469 -0.00316828 57.8993 1.83671e-05ZM57.8993 29.1717C55.363 29.1717 52.8837 28.3992 50.7749 26.9519C48.666 25.5045 47.0225 23.4474 46.0519 21.0406C45.0813 18.6338 44.8273 15.9854 45.3221 13.4303C45.8169 10.8753 47.0383 8.5283 48.8317 6.6862C50.6251 4.84411 52.91 3.58962 55.3976 3.08139C57.8851 2.57316 60.4634 2.834 62.8066 3.83094C65.1498 4.82787 67.1526 6.51612 68.5617 8.6822C69.9708 10.8483 70.7229 13.3949 70.7229 16C70.7187 19.492 69.3663 22.8398 66.9623 25.3091C64.5584 27.7783 61.299 29.1674 57.8993 29.1717Z"
        fill="url(#paint2_linear_755_2082)"
      />
      <path
        d="M57.8993 4.44446C55.6742 4.44446 53.4991 5.12218 51.6491 6.39192C49.799 7.66166 48.357 9.46639 47.5055 11.5779C46.654 13.6894 46.4312 16.0128 46.8653 18.2544C47.2994 20.4959 48.3709 22.5549 49.9442 24.171C51.5176 25.7871 53.5222 26.8876 55.7045 27.3335C57.8868 27.7794 60.1488 27.5505 62.2045 26.6759C64.2602 25.8013 66.0172 24.3202 67.2534 22.4199C68.4896 20.5196 69.1494 18.2855 69.1494 16C69.1494 12.9353 67.9641 9.99608 65.8543 7.829C63.7445 5.66191 60.883 4.44446 57.8993 4.44446ZM57.8993 24.7273C56.2198 24.7225 54.5794 24.2066 53.1853 23.2447C51.7911 22.2828 50.7058 20.9181 50.0663 19.323C49.4268 17.7279 49.2619 15.9739 49.5924 14.2826C49.9229 12.5913 50.734 11.0385 51.9232 9.82038C53.1124 8.60227 54.6264 7.77348 56.2739 7.4387C57.9215 7.10392 59.6287 7.27816 61.1799 7.93941C62.731 8.60066 64.0566 9.71926 64.9892 11.1539C65.9218 12.5886 66.4195 14.2749 66.4195 16C66.4153 18.3175 65.5156 20.5385 63.918 22.1749C62.3204 23.8114 60.1555 24.7294 57.8993 24.7273Z"
        fill="url(#paint3_linear_755_2082)"
      />
      <path
        d="M25.3368 1.83671e-05C22.256 1.83671e-05 19.2443 0.938401 16.6826 2.6965C14.121 4.4546 12.1244 6.95346 10.9455 9.87707C9.76646 12.8007 9.45797 16.0178 10.059 19.1214C10.6601 22.2251 12.1436 25.0761 14.3221 27.3137C16.5006 29.5513 19.2762 31.0752 22.2979 31.6926C25.3196 32.3099 28.4516 31.9931 31.2979 30.7821C34.1443 29.5711 36.5771 27.5203 38.2887 24.8891C40.0004 22.2579 40.9139 19.1645 40.9139 16C40.9139 11.7565 39.2728 7.68689 36.3515 4.68631C33.4302 1.68573 29.4681 1.83671e-05 25.3368 1.83671e-05ZM25.3368 29.1717C22.8006 29.1717 20.3212 28.3992 18.2124 26.9519C16.1036 25.5045 14.46 23.4474 13.4894 21.0406C12.5188 18.6338 12.2648 15.9854 12.7596 13.4303C13.2544 10.8753 14.4758 8.5283 16.2692 6.6862C18.0626 4.84411 20.3475 3.58962 22.8351 3.08139C25.3226 2.57316 27.901 2.834 30.2442 3.83094C32.5874 4.82787 34.5902 6.51612 35.9992 8.6822C37.4083 10.8483 38.1604 13.3949 38.1604 16C38.1562 19.492 36.8038 22.8398 34.3999 25.3091C31.9959 27.7783 28.7366 29.1674 25.3368 29.1717Z"
        fill="url(#paint4_linear_755_2082)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_755_2082"
          x1="73.5"
          y1="-3.19093e-06"
          x2="0.5"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0126676" stopColor="#FDEB8D" />
          <stop offset="1" stopColor="#FFBCA0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_755_2082"
          x1="73.5"
          y1="-3.19093e-06"
          x2="0.5"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0126676" stopColor="#FDEB8D" />
          <stop offset="1" stopColor="#FFBCA0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_755_2082"
          x1="73.5"
          y1="-3.19093e-06"
          x2="0.5"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0126676" stopColor="#FDEB8D" />
          <stop offset="1" stopColor="#FFBCA0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_755_2082"
          x1="73.5"
          y1="-3.19093e-06"
          x2="0.5"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0126676" stopColor="#FDEB8D" />
          <stop offset="1" stopColor="#FFBCA0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_755_2082"
          x1="73.5"
          y1="-3.19093e-06"
          x2="0.5"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0126676" stopColor="#FDEB8D" />
          <stop offset="1" stopColor="#FFBCA0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Hundred
