import React, { useRef, useEffect, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import PropTypes from 'prop-types'
import {
  showOverlayNav,
  showMenuMask,
  updateHandleHeroImage,
  setHeaderLight,
  setHeaderDark,
  useStore,
} from '@Store'
import usePortal from 'react-useportal'
import gsap from 'gsap'
import * as THREE from 'three'
// import Stats from 'three/examples/jsm/libs/stats.module'
import Spacer from '@components/Spacer'
import Button from '@components/Button'
import { colors } from '@styles/vars/colors.style'
import {
  HeroInner,
  HeroText,
  HeroButton,
  HeroMain,
  // SceneDebug,
  SceneCanvas,
  HeroScroller,
} from './index.style'

// Images
import LightImg from './images/light.png'
import Arch1Img from './images/arch-1.png'
import Arch2Img from './images/arch-2.png'
import Arch3Img from './images/arch-3.png'
import Arch4Img from './images/arch-4.png'
import Arch5Img from './images/arch-5.png'
import Arch6Img from './images/arch-6.png'
import Arch7Img from './images/arch-7.png'
import Arch8Img from './images/arch-8.png'
import Arch9Img from './images/arch-9.png'
import { Heading1, TextBody } from '@components/TextStyles'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { startWebgl } from '@Store/'
import { isIOS } from 'react-device-detect'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import { localise } from '@utils/localise'

// const dat = typeof window !== 'undefined' ? require('dat.gui') : null

const postProcessingMaterial = ({
  pageTexture,
  pageScroll,
  scrollableHeight,
  colorFloodAmount,
}) => {
  return new THREE.ShaderMaterial({
    uniforms: {
      pageTexture: { type: 't', value: pageTexture },
      pageScroll: { value: pageScroll },
      scrollableHeight: { value: scrollableHeight },
      colorFloodAmount: { value: colorFloodAmount },
    },

    vertexShader: `
      varying vec2 vUv;

      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
      }
    `,

    fragmentShader: `
      uniform sampler2D pageTexture;
      uniform float pageScroll;
      uniform float scrollableHeight;
      uniform float colorFloodAmount;
      varying vec2 vUv;

      void main() {
        vec2 uv = vUv;
        vec4 scene = texture(pageTexture, uv);
        vec3 effectColor = vec3(0.925, 0.922, 0.918);
        float effectScrollPoint = scrollableHeight - pageScroll;
        float pct = distance(uv, vec2(0.5, 0.0));
        effectScrollPoint = smoothstep(0.0, 1.0, effectScrollPoint + pct);
        effectScrollPoint = min(max(effectScrollPoint, 0.0), 1.0);

        vec3 sceneWithEffect = mix(effectColor, scene.rgb, min(1.0 - colorFloodAmount, effectScrollPoint));

        gl_FragColor = vec4(sceneWithEffect, scene.a);
      }
    `,
  })
}

const imageMaterial = ({
  imageTexture,
  lightTexture,
  lightIntensity,
  lightScale,
}) => {
  return new THREE.ShaderMaterial({
    uniforms: {
      imageTexture: { type: 't', value: imageTexture },
      lightTexture: { type: 't', value: lightTexture },
      lightIntensity: { value: lightIntensity },
      lightScale: { value: lightScale },
    },

    vertexShader: `
      varying vec2 vUv;

      void main() {
        vUv = uv;
        vec4 base = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        gl_Position = base;
      }
    `,

    fragmentShader: `
      uniform sampler2D imageTexture;
      uniform sampler2D lightTexture;
      uniform float lightIntensity;
      uniform float lightScale;
      varying vec2 vUv;

      void main() {
        vec2 uv = vUv;

        vec4 tex1 = texture(imageTexture, vec2(uv[0], uv[1]));
        vec4 tex2 = texture(lightTexture, vec2((uv[0] / lightScale) - (1.0 - lightScale) * 0.65, uv[1] / lightScale));
        
        vec4 combine = mix(tex1, tex2, vec4(min(tex2.a, lightIntensity)));

        vec4 from = vec4(0.0, 0.0, 0.0, 0.0);
        vec4 to = combine;

        gl_FragColor = mix(from, to, 1.0);
      }
    `,
  })
}

const Hero = ({ debug, description }) => {
  const [store, dispatch] = useStore()
  const { smoothScroll } = store

  useEffect(() => {
    smoothScrollRef.current = smoothScroll
  }, [smoothScroll])

  const [inViewRef, inView] = useInView({
    triggerOnce: false,
    rootMargin: '-50% 0px 0px 0px',
  })

  useEffect(() => {
    if (inView) {
      setHeaderLight(dispatch)
    } else {
      setHeaderDark(dispatch)
    }
  }, [inView, dispatch])

  // If the hero is in view when closing the overlay nav set the header theme to light
  useEffect(() => {
    if (!store.overlayNavIsOpen && !store.showMenuMask && inView) {
      setHeaderLight(dispatch)
    }
  }, [inView, dispatch, store.overlayNavIsOpen, store.showMenuMask])

  const { Portal } = usePortal()
  const smoothScrollRef = useRef()

  // Scroll options
  const scrollEffects = useRef({
    scrollableHeight: 1, // Number of 'page scrolls' until the bg color should fade to grey
  })

  // DOM refs
  // const debugging = useRef()
  const canvas = useRef(),
    text = useRef(),
    button = useRef()

  // WebGL refs
  const renderer = useRef(),
    scene = useRef(),
    bufferScene = useRef(),
    camera = useRef(),
    stats = useRef()

  // WebGL buffer
  const bufferTexture = useRef(),
    bufferMaterial = useRef(),
    bufferPlane = useRef()

  // Objects
  const elements = useRef([]),
    lightImage = useRef()

  // Animation refs
  const raf = useRef(),
    startTime = useRef(),
    runTime = useRef()

  // Window refs
  const pixelRatioMatch = useRef()

  // Sizing refs
  const sizes = useRef(),
    viewSize = useRef()

  // Memory tracking
  const resources = useRef([])
  // timeouts = useRef([])

  // Interaction tracking
  const mouse = useRef({
    x: 0,
    y: 0,
    scale: 0.3,
    speed: 0.1,
  })

  // Conversion functions
  const convertSizeToShaderUnits = el => {
    let winAspectRatio = window.innerWidth / window.innerHeight

    return {
      width: (el.vw / 100) * viewSize.current.width,
      height:
        (el.vw / el.aspectRatio / 100) *
        winAspectRatio *
        viewSize.current.height,
    }
  }

  const convertPositionToShaderUnits = (geoSize, x, y) => {
    if (x && y) {
      // Modified to calculate position from provided coords
      return {
        x:
          (x / window.innerWidth) * viewSize.current.width -
          viewSize.current.width / 2,
        y:
          viewSize.current.height -
          (y / window.innerHeight) * viewSize.current.height -
          viewSize.current.height / 2,
      }
    } else {
      // Modified to calculate position to be center/bottom
      return {
        x: 0,
        y: -0.5 + geoSize.height / 2,
      }
    }
  }

  const convertScrollToShaderUnits = useCallback(scroll => {
    return (scroll / window.innerHeight) * viewSize.current.height
  }, [])

  // Utils
  // Find the device pixel ratio
  const getPixelRatio = () => {
    return Math.min(window.devicePixelRatio, 1.5)
  }

  // Hash function to ensure Three.js groups are unique
  const hashCode = s => {
    let h = 0,
      l = s.length,
      i = 0

    if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0
    return Math.abs(h)
  }

  // Resize
  const resizeElement = useCallback(element => {
    element.geoSize = convertSizeToShaderUnits(element)
    element.position = convertPositionToShaderUnits(element.geoSize)
    element.offset = {
      y: 0,
    }

    element.plane.scale.set(element.geoSize.width, element.geoSize.height, 1)
  }, [])

  const resizeElements = useCallback(() => {
    elements.current.forEach(element => resizeElement(element))
  }, [resizeElement])

  // Handle Imagery
  const createInWebGL = useCallback((el, scene) => {
    el.plane.name = el.id
    const group = new THREE.Object3D()
    group.name = el.id
    el.group = group
    group.add(el.plane)
    scene.add(group)

    return el
  }, [])

  // const handleLightAnimation = useCallback(() => {
  //   elements.current.forEach((element, elementIndex) => {
  //     gsap.to(element.material.uniforms.lightIntensity, {
  //       keyframes: [
  //         { value: 0, duration: 3 },
  //         { value: 1, duration: 3 },
  //       ],
  //       overwrite: true,
  //       ease: 'power1.inOut',
  //       repeat: -1,
  //       repeatDelay: 0.5,
  //       delay: element.index / -4,
  //     })
  //   })
  // }, [])

  const handleLightImage = useCallback((image, success, error) => {
    // Load the texture
    let loader = new THREE.TextureLoader()

    loader.crossOrigin = '*'

    loader.load(
      image,
      imageBitmap => {
        imageBitmap.minFilter = THREE.NearestFilter
        imageBitmap.magFilter = THREE.NearestFilter

        resources.current = [...resources.current, imageBitmap]

        lightImage.current = imageBitmap

        success()
      },
      undefined,
      error
    )
  }, [])

  const handleImage = useCallback(
    (image, count) => {
      let _image = {
        index: image.index,
        el: image.img,
        parallax: image.parallax || { x: 0, y: 0, scale: 0 },
        vw: image.vw,
        z: image.z,
        noShadow: image.noShadow,
        lightScale: image.lightScale,
        id: hashCode(`${image.img}_${Math.random()}`),
      }

      // Load the texture
      let loader = new THREE.TextureLoader()

      loader.crossOrigin = '*'

      loader.load(_image.el, imageBitmap => {
        let texture = imageBitmap

        texture.minFilter = THREE.NearestFilter
        texture.magFilter = THREE.NearestFilter

        // Get aspect ratio
        _image.aspectRatio = texture.image.width / texture.image.height

        // Create geometry
        let geometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1)

        // Create material
        let material = imageMaterial({
          imageTexture: texture,
          lightTexture: !_image.noShadow && lightImage.current,
          lightIntensity: 1,
          lightScale: _image.lightScale || 1,
        })

        material.transparent = true
        material.needsUpdate = true

        resources.current = [...resources.current, geometry, material]

        // Create Mesh
        const plane = new THREE.Mesh(geometry, material)

        plane.position.set(0, 0, _image.z)

        // Performance hack
        // For one frame we need to render the mesh then cull it.
        // This means the mesh is not rendered until it is visible
        // This helps with perf, but requires at least one frame.
        plane.frustumCulled = false
        requestAnimationFrame(() => {
          plane.frustumCulled = true
        })

        // Update the _image object
        _image.texture = texture
        _image.material = material
        _image.plane = plane
        // Add to the scene
        _image = createInWebGL(_image, bufferScene.current)

        // Store elements
        elements.current = [...elements.current, _image]
        resizeElements()
      })
    },
    [createInWebGL, resizeElements]
  )

  // Events
  const onPointerMove = event => {
    if (event.isPrimary === false) return

    const xMin = 0,
      xMax = window.innerWidth,
      yMin = 0,
      yMax = window.innerHeight

    mouse.current.x = gsap.utils.normalize(xMin, xMax, event.clientX) * 2 - 1
    mouse.current.y = 1 - gsap.utils.normalize(yMin, yMax, event.clientY)
  }

  // Animation loop
  const updateElements = useCallback(
    scroll => {
      // Update scroll uniform on buffer (material that affects whole canvas)
      bufferMaterial.current.uniforms.pageScroll.value = scroll

      // Update text dom elements
      const scrollMinusDelay = scroll - 0.1

      if (scrollMinusDelay > 0) {
        text.current.style.transform = `scale(${
          scrollMinusDelay * 0.5 + 1
        }) translateY(${scrollMinusDelay * -200}px)`
        text.current.style.opacity = 1 - scrollMinusDelay * 2
        button.current.style.opacity = 1 - scrollMinusDelay * 2
      }

      // Update planes
      elements.current.forEach((element, index) => {
        // Update plane scale
        const scaleFactor = 1 + scroll * element.parallax.scale

        element.plane.scale.set(
          element.geoSize.width * scaleFactor,
          element.geoSize.height * scaleFactor,
          1
        )

        // Calculate plane offsets based on mouse position
        const xPosOffset =
            mouse.current.x * element.parallax.x * Math.max(1 - scroll, 0),
          yPosOffset = mouse.current.y * element.parallax.y

        // Update plane x positions
        element.plane.position.x +=
          (element.position.x + xPosOffset - element.plane.position.x) * 0.1

        // Update plane y positions
        element.offset.y += (yPosOffset - element.offset.y) * 0.1
        element.plane.position.y =
          -0.5 + (element.geoSize.height * scaleFactor) / 2 + element.offset.y
      })
    },
    [text]
  )

  const updateScene = useCallback(() => {
    renderer.current.setRenderTarget(bufferTexture.current)
    renderer.current.render(bufferScene.current, camera.current)
    bufferMaterial.current.uniforms.pageTexture.value =
      bufferTexture.current.texture
    renderer.current.setRenderTarget(null)

    // render final scene
    renderer.current.render(scene.current, camera.current)
  }, [])

  const renderLoop = useCallback(
    timestamp => {
      if (!startTime.current) {
        startTime.current = timestamp
      }
      runTime.current = timestamp - startTime.current
      if (stats.current) stats.current.update()

      const currentScroll = convertScrollToShaderUnits(
        smoothScrollRef.current.scrollTop()
      )

      if (scrollEffects.current.scrollableHeight + 1 > currentScroll) {
        bufferMaterial.current.uniforms.colorFloodAmount.value = 0
        updateElements(currentScroll)
        updateScene()
      } else if (bufferMaterial.current.uniforms.colorFloodAmount.value === 0) {
        // Once past the scrollable section run a single frame with all colors removed
        bufferMaterial.current.uniforms.colorFloodAmount.value = 1
        updateScene()
      }

      raf.current = window.requestAnimationFrame(renderLoop)
    },
    [
      startTime,
      runTime,
      updateElements,
      updateScene,
      convertScrollToShaderUnits,
    ]
  )

  // Begin
  useEffect(() => {
    if (window.innerWidth < breakpoints.desk || !canvas.current) return

    startWebgl(dispatch)

    pixelRatioMatch.current =
      typeof window !== `undefined`
        ? window.matchMedia('screen and (min-resolution: 2dppx)')
        : null

    // Setup functions
    const setScenes = () => {
      scene.current = new THREE.Scene()
      scene.current.background = new THREE.Color(colors.darkblue)

      if (debug) {
        scene.current.add(new THREE.AxesHelper())
      }

      bufferScene.current = new THREE.Scene()
      bufferScene.current.background = new THREE.Color(colors.darkblue)
    }

    const setSizes = () => {
      sizes.current = {
        width: window.innerWidth,
        height: window.innerHeight,
      }

      viewSize.current = {
        height: 1,
        width: 1,
      }
    }

    const setBuffer = () => {
      bufferTexture.current = new THREE.WebGLRenderTarget(
        sizes.current.width,
        sizes.current.height,
        { minFilter: THREE.LinearFilter, magFilter: THREE.NearestFilter }
      )

      let bufferGeometry = new THREE.PlaneBufferGeometry(1, 1)
      bufferMaterial.current = postProcessingMaterial({
        pageTexture: bufferTexture.current,
        pageScroll: 0,
        scrollableHeight: scrollEffects.current.scrollableHeight,
        colorFloodAmount: 0,
      })

      resources.current = [
        ...resources.current,
        bufferGeometry,
        bufferMaterial.current,
      ]

      bufferPlane.current = new THREE.Mesh(
        bufferGeometry,
        bufferMaterial.current
      )
      bufferPlane.current.scale.set(1, 1, 1)
      bufferPlane.current.material.needsUpdate = true

      scene.current.add(bufferPlane.current)
    }

    const setCamera = () => {
      camera.current = new THREE.OrthographicCamera(
        -viewSize.current.width / 2,
        viewSize.current.width / 2,
        viewSize.current.height / 2,
        -viewSize.current.height / 2,
        -100,
        100
      )
      scene.current.add(camera.current)
    }

    setScenes()
    setSizes()
    setBuffer()
    setCamera()

    if (window.innerWidth >= breakpoints.desk)
      window.addEventListener('pointermove', onPointerMove)

    // const setDebug = () => {
    //   if (typeof document !== 'undefined') {
    //     stats.current = new Stats()
    //     debugging.current.appendChild(stats.current.dom)
    //   }
    // }

    // const setGUI = () => {
    //   const gui = new dat.GUI({ autoPlace: false, width: 360, zIndex: 1000 })
    //   const scrollGUI = gui.addFolder('Scroll Effects')
    //   scrollGUI
    //     .add(scrollEffects.current, 'imageParallax')
    //     .min(0)
    //     .max(0.5)
    //     .step(0.05)
    //     .onChange(value => {
    //       const minScale = 1 + value
    //       elements.current.forEach((element, index) => {
    //         // Update minimum scale of images to allow for parallax
    //         if (element.material.uniforms.imageMinScale) {
    //           element.material.uniforms.imageMinScale.value = minScale
    //         }
    //       })
    //     })
    //   scrollGUI.add(scrollEffects.current, 'scale')
    //   debugging.current.appendChild(gui.domElement)
    // }

    // if (debug) {
    //   setDebug()
    //   // if (dat) setGUI()
    // }

    const resizeHandler = () => {
      // Update sizes
      sizes.current.width = window.innerWidth
      sizes.current.height = window.innerHeight

      // Update camera
      camera.current.left = -viewSize.current.width / 2
      camera.current.right = viewSize.current.width / 2
      camera.current.top = viewSize.current.height / 2
      camera.current.bottom = -viewSize.current.height / 2
      camera.current.updateProjectionMatrix()

      // Update Elements
      resizeElements()

      // Buffer texture
      bufferTexture.current.setSize(sizes.current.width, sizes.current.height)

      // Buffer Plane
      bufferPlane.current.scale.set(
        viewSize.current.width,
        viewSize.current.height,
        1
      )

      // Update renderer
      renderer.current.setSize(sizes.current.width, sizes.current.height)
      renderer.current.setPixelRatio(getPixelRatio())
    }

    const setResize = () => {
      window.addEventListener('resize', resizeHandler)

      try {
        pixelRatioMatch.current.addEventListener('change', resizeHandler)
      } catch (error) {
        try {
          pixelRatioMatch.current.addListener(resizeHandler)
        } catch (error2) {
          console.error(error2)
        }
      }
    }

    setResize()

    const setRenderer = () => {
      renderer.current = new THREE.WebGLRenderer({
        canvas: canvas.current,
        antialias: false,
        alpha: false,
        powerPreference: 'high-performance',
        precision: 'highp',
        preserveDrawingBuffer: false,
        failIfMajorPerformanceCaveat: true,
      })
      renderer.current.outputEncoding = THREE.sRGBEncoding
      renderer.current.autoClear = true
      renderer.current.setSize(sizes.current.width, sizes.current.height)
      renderer.current.setPixelRatio(getPixelRatio())
    }

    setRenderer()

    raf.current = window.requestAnimationFrame(renderLoop)

    return () => {
      // Dispose of any timeouts
      // timeouts.current.forEach(timeout => {
      //   clearTimeout(timeout)
      // })
      // Dispose of resources
      resources.current.forEach(resource => {
        resource.dispose()
      })
      renderer.current && renderer.current.dispose()
      window && window.removeEventListener('pointermove', onPointerMove)

      raf.current && window.cancelAnimationFrame(raf.current)
      window.removeEventListener('resize', resizeHandler)

      try {
        pixelRatioMatch.current.removeEventListener('change', resizeHandler)
      } catch (error) {
        try {
          pixelRatioMatch.current.removeListener(resizeHandler)
        } catch (secondaryError) {
          console.error(secondaryError)
        }
      }
    }
  }, [debug, renderLoop, resizeElements, dispatch])

  useEffect(() => {
    if (window.innerWidth < breakpoints.desk || !canvas.current) return

    // Arch Images
    const arches = [
      {
        index: 1,
        img: Arch1Img,
        vw: 100,
        z: -0.8,
        parallax: {
          x: 0,
          y: 0,
          scale: scrollEffects.current.scrollableHeight * 2,
        },
      },
      {
        index: 2,
        img: Arch2Img,
        vw: 90,
        z: -0.7,
        lightScale: 0.98,
        parallax: {
          x: -0.005,
          y: -0.005,
          scale: scrollEffects.current.scrollableHeight * 1.95,
        },
      },
      {
        index: 3,
        img: Arch3Img,
        vw: 80,
        z: -0.6,
        lightScale: 0.95,
        parallax: {
          x: -0.01,
          y: -0.01,
          scale: scrollEffects.current.scrollableHeight * 1.9,
        },
      },
      {
        index: 4,
        img: Arch4Img,
        vw: 70,
        z: -0.5,
        lightScale: 0.92,
        parallax: {
          x: -0.015,
          y: -0.015,
          scale: scrollEffects.current.scrollableHeight * 1.85,
        },
      },
      {
        index: 5,
        img: Arch5Img,
        vw: 60,
        z: -0.4,
        lightScale: 0.89,
        parallax: {
          x: -0.02,
          y: -0.02,
          scale: scrollEffects.current.scrollableHeight * 1.8,
        },
      },
      {
        index: 6,
        img: Arch6Img,
        vw: 50,
        z: -0.3,
        lightScale: 0.86,
        parallax: {
          x: -0.025,
          y: -0.025,
          scale: scrollEffects.current.scrollableHeight * 1.75,
        },
      },
      {
        index: 7,
        img: Arch7Img,
        vw: 40,
        z: -0.2,
        lightScale: 0.82,
        parallax: {
          x: -0.03,
          y: -0.03,
          scale: scrollEffects.current.scrollableHeight * 1.7,
        },
      },
      {
        index: 8,
        img: Arch8Img,
        vw: 29.9,
        z: -0.1,
        lightScale: 0.72,
        parallax: {
          x: -0.035,
          y: -0.035,
          scale: scrollEffects.current.scrollableHeight * 1.64,
        },
      },
      {
        index: 9,
        img: Arch9Img,
        vw: 19.5,
        z: 0,
        noShadow: true,
        parallax: {
          x: -0.04,
          y: -0.04,
          scale: scrollEffects.current.scrollableHeight * 1.6,
        },
      },
    ]

    // Load light image and then arches

    const loadLightImage = new Promise((success, error) =>
      handleLightImage(LightImg, success, error)
    )

    loadLightImage.then(() => {
      if (elements.current.length === 0) {
        arches.forEach((arch, archIndex) => handleImage(arch, archIndex))
      }
    })
  }, [handleLightImage, handleImage])

  useEffect(() => {
    if (!canvas.current) return

    handleImage && updateHandleHeroImage(dispatch, handleImage)
  }, [dispatch, handleImage])

  const openNav = () => {
    showMenuMask(dispatch)
    showOverlayNav(dispatch)
  }

  return (
    <>
      <HeroMain ref={inViewRef} showBackground={!store.webglIsActive}>
        {/* <SceneDebug ref={debugging} /> */}
        {!isIOS && (
          <Portal>
            <SceneCanvas ref={canvas} />
          </Portal>
        )}
        <HeroInner>
          <HeroText ref={text}>
            <Heading1 maxWidth={4.9}>
              <AnimateSplitText type={`lines,chars`}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${localise('The First')} <i>1</i>00 ${localise(
                      'Years'
                    )}`,
                  }}
                />
              </AnimateSplitText>
            </Heading1>
            <Spacer size={30} />
            <TextBody>
              <AnimateSplitText delay={0.3}>
                {description.heroDescription}
              </AnimateSplitText>
            </TextBody>
          </HeroText>
          <AnimateFadeIn delay={1}>
            <HeroButton ref={button}>
              <Button onClick={openNav}>{localise('Take the Tour')}</Button>
            </HeroButton>
          </AnimateFadeIn>
        </HeroInner>
        <HeroScroller />
      </HeroMain>
    </>
  )
}

Hero.propTypes = {
  debug: PropTypes.bool,
}

Hero.defaultProps = {
  debug: true,
}

export default Hero
