import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import { Heading3 } from '@components/TextStyles'
import {
  FiltersMain,
  FiltersText,
  FiltersImage,
  FiltersLinks,
} from './index.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { GatsbyImage } from 'gatsby-plugin-image'
import { colors } from '@styles/vars/colors.style'
import Button from '@components/Button'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import { animation } from '@styles/vars/animation.style'
import AnimateImage from '@components/animation/AnimateImage'

const Filters = ({ title, links, images }) => {
  return (
    <FiltersMain>
      <Container>
        <Grid>
          <GridItem
            mobile={6}
            tabletP={4}
            tabletPStart={2}
            tabletPAlign={`center`}
            tabletL={2}
            tabletLAlign={`center`}
            desk={2}
            deskAlign={`center`}
          >
            {images[0] && (
              <FiltersImage>
                <AnimateImage
                  delay={animation.imageStagger * 2}
                  parallax={0.96}
                >
                  <GatsbyImage
                    image={images[0].gatsbyImageData}
                    alt={images[0].description}
                  />
                </AnimateImage>
              </FiltersImage>
            )}
          </GridItem>
          <GridItem
            mobile={6}
            mobileStart={7}
            tabletP={6}
            tabletPStart={6}
            tabletL={3}
            tabletLStart={3}
            tabletLAlign={`center`}
            desk={3}
            deskStart={3}
            deskAlign={`center`}
          >
            {images[1] && (
              <FiltersImage>
                <AnimateImage delay={animation.imageStagger} parallax={0.94}>
                  <GatsbyImage
                    image={images[1].gatsbyImageData}
                    alt={images[1].description}
                  />
                </AnimateImage>
              </FiltersImage>
            )}
          </GridItem>
          <GridItem
            tabletP={10}
            tabletPStart={2}
            tabletL={5}
            tabletLStart={7}
            tabletLAlign={`center`}
            desk={3}
            deskStart={7}
            deskAlign={`center`}
          >
            <FiltersText>
              <Heading3 color={colors.pink2}>
                <AnimateSplitText>{title}</AnimateSplitText>
              </Heading3>
              <Spacer size={[50, 52]} />
              <FiltersLinks>
                {React.Children.toArray(
                  links.map((link, linkIndex) => {
                    return (
                      <li>
                        <AnimateSlideIn
                          delay={animation.lineDuration * linkIndex}
                        >
                          <Button
                            variant="secondary"
                            type="externalLink"
                            href={link.url}
                            target="_blank"
                          >
                            {link.text}
                          </Button>
                        </AnimateSlideIn>
                      </li>
                    )
                  })
                )}
              </FiltersLinks>
            </FiltersText>
          </GridItem>
          <GridItem desk={2} deskStart={11} deskAlign={`center`}>
            {images[2] && (
              <FiltersImage desktopOnly={true}>
                <AnimateImage
                  delay={animation.imageStagger * 3}
                  parallax={0.98}
                >
                  <GatsbyImage
                    image={images[2].gatsbyImageData}
                    alt={images[2].description}
                  />
                </AnimateImage>
              </FiltersImage>
            )}
          </GridItem>
        </Grid>
        <Spacer size={[100, 200]} />
      </Container>
    </FiltersMain>
  )
}

Filters.propTypes = {
  title: PropTypes.string,
  links: PropTypes.array,
  images: PropTypes.array,
}

export default Filters
