import React, { useEffect, useState } from 'react'
import {
  ImageCarouselMain,
  ImageCarouselItems,
  ImageCarouselButtons,
  ImageCarouselItemList,
  ImageCarouselItem,
  ImageCarouselDots,
  ImageCarouselDot,
} from './index.style'
import { Heading3, TextBody } from '@components/TextStyles'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { GatsbyImage } from 'gatsby-plugin-image'
import { colors } from '@styles/vars/colors.style'
import Button from '@components/Button'
import IconArrowRight from '@svgs/IconArrowRight'
import { useSwipeable } from 'react-swipeable'
import { breakpoints } from '@styles/vars/breakpoints.style'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import AnimateImage from '@components/animation/AnimateImage'
import { animation } from '@styles/vars/animation.style'
import { localise } from '@utils/localise'

const ImageCarousel = ({ title, text, images }) => {
  const [index, setIndex] = useState(0)
  const [offset, setOffset] = useState(3)

  const previous = () => {
    setIndex(index > 0 ? index - 1 : index)
  }

  const next = () => {
    setIndex(index < images.length - offset ? index + 1 : index)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (window.innerWidth < breakpoints.desk) {
        next()
      }
    },
    onSwipedRight: () => {
      if (window.innerWidth < breakpoints.desk) {
        previous()
      }
    },
  })

  useEffect(() => {
    setOffset(window.innerWidth >= 1024 ? 3 : 1)

    const updateOffset = () => {
      setOffset(window.innerWidth >= 1024 ? 3 : 1)
    }

    window.addEventListener('resize', updateOffset)

    return () => {
      window.removeEventListener('resize', updateOffset)
    }
  }, [])

  return (
    <ImageCarouselMain>
      <Spacer size={[91, 207]} />
      <Container>
        <Grid>
          <GridItem tabletP={10} tabletPStart={2} desk={6} deskStart={2}>
            <Heading3 color={colors.pink2}>
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading3>
            <Spacer size={[26, 35]} />
            <TextBody>
              <AnimateSplitText>{text.instagramText}</AnimateSplitText>
            </TextBody>
          </GridItem>
        </Grid>
      </Container>
      <ImageCarouselItems {...handlers}>
        <ImageCarouselButtons>
          <Container>
            <AnimateSlideIn>
              <Button
                label={localise('Previous')}
                iconRight={<IconArrowRight />}
                iconOnly={true}
                flip={true}
                color={colors.dark}
                onClick={previous}
                disabled={index === 0}
              ></Button>
            </AnimateSlideIn>
            <AnimateSlideIn delay={animation.wordDuration}>
              <Button
                label={localise('Next')}
                iconRight={<IconArrowRight />}
                iconOnly={true}
                color={colors.dark}
                onClick={next}
                disabled={index >= images.length - offset - 1}
              ></Button>
            </AnimateSlideIn>
          </Container>
        </ImageCarouselButtons>
        <Container>
          <Spacer size={42} />
          <Grid>
            <GridItem
              mobile={10}
              tabletP={8}
              tabletPStart={2}
              tabletL={4}
              tabletLStart={2}
              desk={3}
              deskStart={2}
            >
              <ImageCarouselItemList>
                {React.Children.toArray(
                  images.map((image, imageIndex) => {
                    return (
                      <ImageCarouselItem x={100 * (imageIndex - index)}>
                        {image.description ? (
                          <a
                            href={image.description}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <AnimateImage
                              parallax={animation.negativeSpeeds[imageIndex]}
                            >
                              <GatsbyImage
                                image={image.gatsbyImageData}
                                alt={image.title}
                              />
                            </AnimateImage>
                          </a>
                        ) : (
                          <AnimateImage
                            parallax={animation.negativeSpeeds[imageIndex]}
                          >
                            <GatsbyImage
                              image={image.gatsbyImageData}
                              alt={image.title}
                            />
                          </AnimateImage>
                        )}
                      </ImageCarouselItem>
                    )
                  })
                )}
              </ImageCarouselItemList>
            </GridItem>
          </Grid>
        </Container>
      </ImageCarouselItems>
      <ImageCarouselDots>
        {React.Children.toArray(
          images.map((item, itemIndex) => {
            return <ImageCarouselDot active={index === itemIndex} />
          })
        )}
      </ImageCarouselDots>
      <Spacer size={[109, 230]} />
    </ImageCarouselMain>
  )
}

export default ImageCarousel
