import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import { Heading3, TextBody, Caption } from '@components/TextStyles'
import { ImageTextStyle, IntroText, ImageTextImage } from './index.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { colors } from '@styles/vars/colors.style'
import Button from '@components/Button'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import AnimateImage from '@components/animation/AnimateImage'
import BookVideo from '@components/Home/BookVideo'

const ImageText = ({ subheading, title, text, cta, link, image }) => {
  return (
    <ImageTextStyle>
      <Container>
        <Grid>
          <GridItem
            tabletP={10}
            tabletPStart={2}
            tabletL={8}
            tabletLStart={3}
            desk={5}
            deskStart={2}
            deskAlign={`center`}
            deskM={4}
            deskMStart={2}
            deskMAlign={`center`}
          >
            <IntroText>
              <Caption color={colors.pink2}>
                <AnimateSplitText>{subheading}</AnimateSplitText>
              </Caption>
              <Spacer size={[16, 29]} />
              <Heading3 color={colors.pink2}>
                <AnimateSplitText>{title}</AnimateSplitText>
              </Heading3>
              <Spacer size={[29, 36]} />
              <TextBody>
                <AnimateSplitText>{text.coffeetableText}</AnimateSplitText>
              </TextBody>
              <Spacer size={[50, 60]} />
              <AnimateSlideIn>
                <Button
                  variant="secondary"
                  type="externalLink"
                  href={link}
                  target="_blank"
                >
                  {cta}
                </Button>
              </AnimateSlideIn>
            </IntroText>
          </GridItem>
          <GridItem
            tabletP={8}
            tabletPStart={2}
            tabletL={8}
            tabletLStart={3}
            desk={4}
            deskStart={8}
            deskM={5}
            deskMStart={7}
          >
            <ImageTextImage>
              <AnimateImage parallax={1}>
                <BookVideo />
              </AnimateImage>
            </ImageTextImage>
          </GridItem>
        </Grid>
        <Spacer size={[64, 122]} />
      </Container>
    </ImageTextStyle>
  )
}

ImageText.propTypes = {
  subheading: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.object,
  cta: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.object,
}

export default ImageText
