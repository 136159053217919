import { clamp } from '@styles/utils/conversion.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'

export const CardsMain = styled.div`
  ${clamp('height', 440, 1400)};
  ${clamp('margin-bottom', 50, 250)};
  overflow: hidden;
  position: relative;
`

export const CardsInner = styled.div`
  left: 50%;
  margin-left: -90%;
  ${clamp('margin-top', -271, -710)};
  position: absolute;
  top: 50%;
  transform: rotateZ(-12deg) translateZ(0);
  width: 180%;

  ${mq.tabletP} {
    margin-left: -75%;
    width: 150%;
  }

  ${mq.tabletL} {
    margin-left: -55%;
    width: 110%;
  }

  ${mq.contained} {
    margin-left: calc(${breakpoints.contained * 1.1 * -0.5}px);
    width: calc(${breakpoints.contained * 1.1}px);
  }
`

export const CardsPair = styled.div``

export const CardsSingle = styled.div`
  border-radius: 10px;
  overflow: hidden;

  :first-of-type {
    ${clamp('margin-bottom', 9, 24)};
  }
`
