import styled from 'styled-components'
import { mq } from '../../../styles/vars/media-queries.style'

export const ImageTextStyle = styled.div``

export const IntroText = styled.div`
  ${mq.desk} {
    margin: 0 auto;
  }
`

export const ImageTextImage = styled.div`
  border-radius: 1rem;
  margin-top: 5.6rem;
  overflow: hidden;

  ${mq.desk} {
    margin-top: 0;
  }
`
